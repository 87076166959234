<template lang="pug">
  .table-page(ref="tablePage")
    v-card-head
      v-card-head-label
        h2.title {{ 'pages.administration.organisation_chart' | translate }}
        p.subtitle {{ 'pages.administration.manage_departments_positions_skill_profiles_and_resposibilities' | translate }}

      v-card-head-label
        span.link-text(@click="$refs.tableTree.collapseAll()")
          | {{ 'ui.buttons.collapse_all' | translate }}

    vue-scroll(:ops="ops"  @handle-scroll ="handleScroll")
      v-table
        v-table-head
          th.table-page__table-header
            span(style="width:auto") {{ 'ui.labels.department' | translate }}
            div.flex.header-right--titles
              span.text-right {{ 'pages.administration.employee_count' | translate }}
              span.text-right {{ 'ui.labels.skill_assigned' | translate }}

        v-table-body(
          :cols="1"
          :empty="!departments.length"
          :loaded="loaded"
          :no-result="!departments.length")
          v-scrollable(
            :bottom-offset="10")

            v-table-tree(
              ref="tableTree"
              v-model="departments"
              show-last-item
              allow-toggle-branch
              :is-last-item-selected="isNotAssignedSelected"
              :class="{ 'sl-vue-tree--external-drag': externalDragMode }"
              :external-dragging="externalDragMode"
              @select="onSelect"
              @drop="onDrop"
              @externaldrop="onExternalDrop"
              @lastitemclick="onNotAssignedSelect"
              @rootlastitemclick="addDepartment({ toNode: null })")
              template(
                slot="toggle"
                slot-scope="{ node }")
                .sl-vue-tree__chevron
                  v-chevron(
                    list-mode
                    :value="node.isExpanded")
              template(
                slot="title"
                slot-scope="{ node }")
                span.sl-vue-tree__node-icon(
                  v-if="node.isLeaf")
                  i.far.fa-id-card
                span.sl-vue-tree__node-title.mr-2
                  v-editable(
                    ghost
                    toggle-by-icon-only
                    :init-state="node.isEditing"
                    :error-text="node.data.errorText"
                    pencil-class="v-table-tree--show-on-hover"
                    @close="onTitleUpdate($event, node)")
                    span {{ node.title }}
                i.icon.icon--xsm.icon--warning(
                  v-if="!node.isLeaf && node.data.supervisor === null"
                  v-tooltip="'Supervisor not assigned'")

              template(
                slot="sidebar"
                slot-scope="{ node }")
                span.sidebar-column {{ node.data.allChildEmployeeCount || node.data.employeeCount }}
                span.sidebar-column
                  template(v-if="node.isLeaf")
                    | {{ node.data.badgeCount }}
                .sidebar-controls.v-table-tree--show-on-hover.action-buttons--hover(
                  :style="{left: `${posLeft}px`}"
                  @mousedown.stop
                  @mouseup.stop)
                  v-btn.mr-2(
                    v-if="!node.isLeaf && !node.data.isNew"
                    icon
                    stop-propagation
                    @click="addDepartment({ toNode: node })")
                    span +
                    i.far.fa-building
                  v-btn.mr-2(
                    v-if="!node.isLeaf && !node.data.isNew"
                    icon
                    stopPropagation
                    @click="addPosition({ toNode: node })")
                    span +
                    i.far.fa-id-card
                  v-btn(
                    v-if="node.isLeaf && !node.data.isNew"
                    icon
                    stop-propagation
                    v-tooltip="$t('infotext.copy_position')"
                    @click="copyPosition({ node: node })")
                    i.fa.fa-clone
                  v-btn(
                    icon
                    danger
                    stop-propagation
                    v-tooltip="$t('infotext.remove')"
                    @click="onRemove(node)"
                    @mousedown.native="onBeforeRemove(node)")
                    i.fa.fa-trash-alt

              template(
                slot="list-end"
                slot-scope="{ node }")
                template(v-if="node")
                  div
                    span.sl-vue-tree__node-icon
                      i.fa.fa-users
                    span.sl-vue-tree__node-title
                      span {{ 'ui.labels.not_assigned' | translate }}
                  .not-assigned
                    span.sidebar-column
                      | {{ node.data.employeeCount }}
                    span.sidebar-column
                    .sidebar-controls

              template(
                slot="root-list-end")
                span
                  span.sl-vue-tree__node-icon
                    i.fa.fa-plus
                  span.sl-vue-tree__node-title {{ 'ui.labels.add_new_department' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MODAL_TYPES } from '@/util/constants.js'

export default {
  name: 'Table',

  async mounted () {
    await this.loadDepartments()
    this.loaded = true
    this.posLeft = this.defaultPosRight()
  },

  data: () => ({
    ops: {
      scrollPanel: {
        scrollingX: true
      },
      rail: {
        gutterOfEnds: '15px'
      }
    },
    loaded: false,
    posLeft: 0
  }),

  methods: {
    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    ...mapActions('organization', [
      'loadDepartments',
      'selectDepartment',
      'selectUnassigned',
      'addDepartment',
      'addPosition',
      'updateItem',
      'removeItem',
      'moveClipboardItemsTo',
      'onMoveItems',
      'copyPosition'
    ]),

    ...mapActions('departments', [
      'loadDepartmentsTree'
    ]),

    onTitleUpdate (value, node) {
      const fields = {
        name: value
      }
      this.updateItem({ node, fields })
    },

    onSelect (nodes) {
      this.selectDepartment(nodes[0])
    },

    onNotAssignedSelect (node) {
      if (node) {
        this.selectUnassigned(node)
      }
    },

    onDrop (nodes, params) {
      this.onMoveItems({ nodes, params })
    },

    onExternalDrop (payload) {
      this.moveClipboardItemsTo(payload.node)
    },

    onBeforeRemove (node) {
      if (node.data.isNew) {
        node.data.isDeleted = true
      }
    },

    onRemove (node) {
      if (!node.children.length && !node.data.employeeCount) {
        this.removeItem({ node: node })
      } else {
        let subtitle = null
        let title = null
        if (!node.isLeaf) {
          subtitle = this.$t('infotext.delete_not_empty_department')
          title = this.$t('ui.labels.warning') + '!'
        } else {
          title = this.$t('ui.modals.delete_message_with_params', { name: node.data.name })
        }

        const options = {
          content: {
            title: title,
            subtitle: subtitle
          },

          props: {
            type: node.isLeaf ? MODAL_TYPES.WARNING_ORANGE : MODAL_TYPES.WARNING_RED
          },

          buttons: {
            apply: {
              label: 'ui.buttons.delete',
              callback: this.removeItem,
              params: { node: node }
            }
          }
        }

        this.openConfirmModal(options)
      }
    },

    defaultPosRight () {
      return this.$refs.tablePage.getBoundingClientRect().width - 130 - 6// 130 - width block actions, 6 - offset right
    },

    handleScroll (vertical, horizontal, nativeEvent) {
      this.posLeft = this.defaultPosRight() + horizontal.scrollLeft
    }

  },

  computed: {
    departments: {
      get () {
        return this.$store.getters['organization/departments']
      },

      set (value) {
        this.$store.commit('organization/SET_DEPARTMENTS', value)
      }
    },

    ...mapGetters('organization', [
      'selectedDepartment',
      'externalDragMode'
    ]),

    isNotAssignedSelected () {
      return this.selectedDepartment &&
        this.selectedDepartment.isNotAssignedSelected
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-page {

    &__table-header {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      padding-left: 24px;
      padding-right: 24px;
        span {
          &.text-right {
            white-space: nowrap;
            width: 120px;
          }
        }
    }

    .sidebar-column {
      width: 120px;
      text-align: right;
    }

    .not-assigned {
      display: flex;
    }

    .sidebar-controls {
      width: 130px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .action-buttons--hover {
    position: absolute;
    background-color: #e7f7f3;
    top: 0;
    bottom: 0;
    width: auto;
    display: flex;
    align-items: center;
  }

  ::v-deep .sl-vue-tree-selected > .sl-vue-tree-node-item{
        .action-buttons--hover {
        background-color: #d0f0e7;
    }
  }

  ::v-deep .sl-vue-tree-node-item{
    gap: 15px;
    padding-right: 24px;
  }
  ::v-deep .sl-vue-tree-title{
    width: auto;
  }
  ::v-deep .sl-vue-tree-sidebar,
  .header-right--titles {
      width: 370px;
      gap: 15px;
  }
</style>
