<template lang="pug">
right-panel-layout
  template
    intro-page(
      v-if="isIntroMode"
      @on-add-department="onAddDepartment")
    table-page(
      v-else
      @on-open-create-modal="openCreateModal")

  template(slot="panel")
    tabs-panel

  v-dialog(
    width="500"
    v-model="modals.showCreate")
    create-modal(
      @close="modals.showCreate = false")

</template>

<script>
import RightPanelLayout from '../common/RightPanelLayout'
import TabsPanel from './common/TabsPanel'

import IntroPage from './pages/Intro'
import TablePage from './pages/Table'
import CreateModal from './modals/CreateModal'

export default {
  name: 'Departments',

  components: {
    RightPanelLayout,
    TabsPanel,
    IntroPage,
    TablePage,
    CreateModal
  },

  data: () => ({
    isIntroMode: false,
    modals: {
      showCreate: false
    }
  }),

  methods: {
    onAddDepartment () {
      this.isIntroMode = false
    },

    openCreateModal () {
      this.modals.showCreate = true
    }
  }
}

</script>

<style lang="scss">
</style>
